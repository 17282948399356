export enum StoryColor {
    White = -1,
    Black = 0,
    Grey = 1,
    Pink = 2,
    Purple = 3,
    Violet = 4,
    Cyan = 5,
    Turquoise = 6,
    Green = 7
}
